.gender_select_card {
    border: 1px solid #ccc;
    padding: 10px;
    width: 200px;
    cursor: pointer;
    -webkit-user-select: none;
    user-select: none;
    background-color: #938f8fa8;
    border-radius: 5px;
    margin: 10px 0 30px;
    position: relative;
    display: flex;
    flex-direction: column;
    align-items: center;
  }
  
  .selected_gender {
    font-size: 16px;
    font-weight: bold;
  }
  
  .gender_options {
    margin-top: 10px;
    /* background-color: #686868b8; */
    /* border: 1px solid #ddd; */
    border-radius: 5px;
    position: relative;
    width: 100%;
    z-index: 1;
    left: 0;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
  }
  
  .gender_option {
    padding: 8px;
    cursor: pointer;
    width: 100%;
  }
  
  .gender_option:hover {
    background-color: #f1f1f1;
  }
  
  .gender_option.selected {
    background-color: #c0c0c0c3;
    width: 100%;
    padding: 8px;
  }
  