/* Контейнер для формы, который занимает весь экран */
.login_container {
    display: flex;
    justify-content: center; /* Центрируем форму по горизонтали */
    align-items: center; /* Центрируем форму по вертикали */
    height: 90vh !important; /* Контейнер занимает всю высоту экрана */
    /* width: 100vh !important; */
}

.login_form {
    background-color: #505050;
    border-radius: 8px;
    box-shadow: 0 4px 10px #0000001a;
    display: flex;
    flex-direction: column;
    padding: 35px 20px 20px;
    width: 300px;
    align-items: center;
    
}

.login_form input {
    margin-bottom: 20px; /* Расстояние между полями формы */
    padding: 10px; /* Внутренний отступ для увеличения размеров полей */
    font-size: 1rem; /* Размер текста внутри полей */
    width: 80%;
}

.login_form button {
    font-weight: bold; 
    border-radius: 5px !important; /* Скругленные углы */
    padding: 10px; /* Внутренний отступ для кнопки */
    background-color: #ff4081; /* Цвет кнопки */
    color: white; /* Цвет текста кнопки */
    border: none; /* Убираем рамку */
    cursor: pointer; /* Изменение курсора на pointer */
    margin-top: 20px; /* Отступ сверху для размещения кнопки ниже полей */
    font-size: 1rem;
    width: 43%;
}

.login_form button:hover {
    background-color: #e63974; /* Цвет при наведении на кнопку */
}

.login_form p {
    color: red; /* Цвет текста для ошибок */
}

form {
    align-items: center;
    display: flex;
    flex-direction: column;
    width: 100%;
}


/* Пример вашего CSS-модуля (googleSignIn.module.css) */
.google_sign_in {
    /* display: inline-block;
    background-color: #fff;
    color: #757575;
    border: 1px solid #ccc; */
    display: flex;
    justify-content: center;
    border-radius: 4px;
    padding: 10px 20px;
    font-size: 16px;
    font-family: Arial, sans-serif;
    cursor: pointer;
    box-shadow: 0 1px 2px rgba(0,0,0,0.1);
    transition: background-color 0.3s ease;
    text-align: center;
    margin-top: 30px;
    margin-bottom: 12px;
  }
  
  .google_sign_in:hover {
    background-color: #f7f7f7;
  }
  
  .google_sign_in img {
    width: 20px;
    vertical-align: middle;
    margin-right: 10px;
  }
  
  .google_container {
    position: relative;
    width: fit-content;
    margin-top: 10px;
  }
  
  .checkbox_container{
    display: flex;
    align-items: center;
    color: white;
    text-decoration-color: white;
  }

  .checkbox_container input{
    width: 55px;
    margin-bottom: 3px;
  }

  .checkbox_container a{
    color: #92dfff;
  }

  .overlay {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    cursor: not-allowed;
    z-index: 1;
  }
  
  .hint {
    position: absolute;
    top: 90px;
    color: #ff0000;
    font-size: 1.6rem;
    margin-bottom: 10px;
    text-align: center;
        
  }

  .error{
    position: absolute;
    top: 70px;
    color: #ff0000;
    font-size: 1.5rem;
    padding-bottom: 18px;
    text-align: center;
    
  }