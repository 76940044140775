

.imgus {
    position: relative;
    width: 100%;
    /* height: 75% !important; */
    
  }
  
 

/* Контейнер для изображений */
.image_slider {
    display: flex;
    transition: transform 0.37s ease-in-out; /* Плавный переход между изображениями */
    width: 100%; /* Ширина слайдера должна быть 100%, чтобы растянуть все изображения */
    /* overflow: hidden; Скрываем изображения, которые выходят за пределы контейнера */
    aspect-ratio: 3 / 4;
}

.styled_imageus {
    flex-shrink: 0; /* Изображения не должны уменьшаться */
    width: 100%; /* Изображение должно занимать всю ширину контейнера */
    height: 450px; /* 
    /* aspect-ratio: 3 / 4 !important; Соотношение сторон 3:4 */
    object-fit: cover; /* Масштабируем изображение, сохраняя пропорции */
    object-position: center; /* Центрируем изображение */
}

  
  .image_slider img {
    min-width: 100%; /* Каждое изображение занимает 100% ширины контейнера */
    max-width: 100%;
    min-height: 100%;
    max-height: 100%;
    overflow: hidden; /* Скрываем выходящие за пределы изображения */
}


.users_bio_card {
    text-shadow: 1px 1px 3px rgba(95, 93, 93, 0.153);
    display: flex !important;
    flex-direction: column !important;
    justify-content: flex-start !important;
    align-items: flex-start !important;
    padding: 10px !important; /* Внутренний отступ для текста */
    /* border: 1px solid #ccc !important; Рамка вокруг блока био */
    border-radius: 0 !important; /* Скругляем углы */
    background-color:var(--background-color); /* Белый фон для карточки био */
    max-width: 450px !important; /*Ограничиваем максимальную ширину
    /* width: calc(100vw - 2px) !important; Ширина карточки */
    width: 99% !important; 
}

.users_bio_card p {
    margin: 5px 0 !important; /* Отступы между элементами */
    font-size: 1rem !important;
    color: var(--text-color);
    padding-left: 10px !important;
    padding-right: 10px;
    
}

.name_age {
    
    display: flex !important;
    justify-content: flex-start !important; /* Распределение элементов по всей ширине */
    align-items: center !important; /* Выравнивание по вертикали */
    width: 100% !important; /* Ширина блока */
    gap: 30px !important; /* Добавляем расстояние между элементами */
    
}

.users_bio {
    font-size: 1.2rem;
    font-weight: 500;
    text-shadow: 1px 1px 3px rgba(95, 93, 93, 0.153);
    color: var(--text-color);
    padding-left: 20px !important; /* Внутренний отступ для текста */
    padding-top: 10px;
    /* height: 25% !important; 25% высоты отводим под описание */
    /* background-color: #000000 !important; Белый фон для описания */
    /* text-align: center !important; Центрируем текст */
    display: flex !important;
    flex-direction: column !important;
    /* justify-content: center !important; */
    text-align: left !important; /* Выравнивание текста по левому краю */
    display: flex !important;
    flex-direction: column !important;
    justify-content: flex-start !important; /* Описание начинается с верха блока */
    align-items: stretch !important; /* Элементы растягиваются на всю ширину */
}



.profile_container {
    text-align: center;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center; /* Центрируем карточки по горизонтали */
    gap: 5px; /* Отступ между карточками */
    padding-top: 10px;
    margin-bottom: 20px; /* Добавляем больше пространства снизу */
}


.profile {
    margin-bottom: 1px ; /* Увеличиваем отступ снизу */
    display: flex;
    flex-direction: column ; /* Выстраиваем фото и описание вертикально */
    width: 99% ; /* Задаем ширину карточки */
    max-width: 450px;
    /* aspect-ratio: 3 / 4 !important; Соотношение сторон 3:4 */
    border-radius: 1px !important; /* Скругляем углы */
    /* overflow: hidden !important; Скрываем части, которые выходят за границы */
    background-color: var(--background-color); /* Фон карточки */
}

.img {
    /* position: relative !important; */
    width: 100% !important;
    /* height: 75% !important; 75% высоты отводим под фото */
    position: relative;

}

.styled_image {
    width: 100% !important;
    height: 100% ;
    object-fit: cover !important; /* Масштабируем изображение, сохраняя пропорции и центрирование */
    object-position: center !important; /* Центрируем фото внутри карточки */
}

.bio_card {
    margin-top: 1px !important; /* Отступ сверху */
    text-shadow: 1px 1px 3px rgba(95, 93, 93, 0.153);
    display: flex !important;
    flex-direction: column !important;
    justify-content: flex-start !important;
    align-items: flex-start !important;
    padding: 1px !important; /* Внутренний отступ для текста */
    border-radius: 1px !important; /* Скругляем углы */
    background-color: var(--background-color); /* Фон для карточки bio */
    max-width: 450px !important; /* Ограничиваем максимальную ширину */
    width: 99% !important;
}

.bio_card p {
    margin: 5px 0 !important; /* Отступы между элементами */
    font-size: 1rem !important;
    color: var(--text-color);
    padding-left: 10px !important;
    padding-right: 10px;
}

.name_age {
    display: flex !important;
    justify-content: flex-start !important; /* Распределение элементов по всей ширине */
    align-items: center !important; /* Выравнивание по вертикали */
    width: 100% !important; /* Ширина блока */
    gap: 30px !important; /* Добавляем расстояние между элементами */
}

.profile_bio {
    font-size: 1.2rem;
    font-weight: 500;
    text-shadow: 1px 1px 3px rgba(95, 93, 93, 0.153);
    color: var(--text-color);
    padding-left: 20px !important; /* Внутренний отступ для текста */
    padding-top: 10px;
    text-align: left !important; /* Выравнивание текста по левому краю */
    display: flex !important;
    flex-direction: column !important;
    justify-content: flex-start !important; /* Описание начинается с верха блока */
    align-items: stretch !important; /* Элементы растягиваются на всю ширину */
    gap: 15px;
}

.btn_profile button {
    font-weight: bold; 
    border-radius: 5px !important; /* Скругленные углы */
    padding: 10px; /* Внутренний отступ для кнопки */
    background-color: #ff4081; /* Цвет кнопки */
    color: white; /* Цвет текста кнопки */
    border: none; /* Убираем рамку */
    cursor: pointer; /* Изменение курсора на pointer */
    margin-top: 20px; /* Отступ сверху для размещения кнопки ниже полей */
    font-size: 1rem;
}

.btn_profile button:hover {
    background-color: #e63974 !important; /* Цвет кнопки при наведении */
    transform: translateY(-3px) !important; /* Плавный подъем кнопки при наведении */
}

.btn_profile button:active {
    background-color: #c72b5f !important; /* Цвет кнопки при клике */
    transform: translateY(0) !important;
}

.btn_profile button:focus {
    outline: none !important;
}





.prev, .next {
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    background-color: rgba(0, 0, 0, 0.5);
    color: white;
    border: none;
    padding: 10px;
    padding-left: 15px;
    padding-right: 15px;
    cursor: pointer;
    border-radius: 50px;
}

.prev {
    left: 10px;
}

.next {
    right: 10px;
}



