.profile_container {
    position: fixed;
    text-align: center;
    display: flex;
    flex-direction: column;
    /* justify-content: center; */
    align-items: center; /* Центрируем карточки по горизонтали */
    gap: 5px; /* Отступ между карточками */
    padding-top: 3px;
    margin-bottom: 20px; /* Добавляем больше пространства снизу */
    width: 100vw;
    height: 100vh;
    margin-top: 6.5vh;
}

@media screen and (max-width: 380px) {
    /* Стили для экранов шире 650px */
    .profile_container {
        position: fixed;
        text-align: center;
        display: flex;
        flex-direction: column;
        /* justify-content: center; */
        align-items: center; /* Центрируем карточки по горизонтали */
        gap: 5px; /* Отступ между карточками */
        padding-top: 3px;
        margin-bottom: 20px; /* Добавляем больше пространства снизу */
        width: 100vw;
        height: 100vh;
        margin-top: 5vh;
    }
}

.profile {
    margin-bottom: 1px ; /* Увеличиваем отступ снизу */
    display: flex;
    flex-direction: column ; /* Выстраиваем фото и описание вертикально */
    width: 98vw; /* Задаем ширину карточки */
    max-width: 450px;
    /* aspect-ratio: 3 / 4 !important; Соотношение сторон 3:4 */
    border-radius: 1px !important; /* Скругляем углы */
    /* overflow: hidden !important; Скрываем части, которые выходят за границы */
    background-color: var(--background-color); /* Фон карточки */
    
}

.img {
    /* position: relative !important; */
    width: 100% !important;
    /* height: 75% !important; 75% высоты отводим под фото */
    position: relative;
    aspect-ratio: 3 / 4;
    max-width: 450px;

}

.styled_image {
    width: 100% !important;
    height: 100% ;
    object-fit: cover !important; /* Масштабируем изображение, сохраняя пропорции и центрирование */
    object-position: center !important; /* Центрируем фото внутри карточки */
    border: 1px;
    border-color: var(--border-color);
    border-style: solid;
    border-radius: 5px;
    /* position: absolute; 
    top: 0;
    left: 0;
    z-index: 1;  */
}

.prev, .next {
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    background-color: rgba(0, 0, 0, 0.438);
    color: rgb(198, 197, 197);
    border: none;
    cursor: pointer;
    border-radius: 50px;
    
    z-index: 15;
    width: 40px;
    height: 40px;
    pointer-events: auto;
    padding-top: 7px;
    padding-bottom: 7px;
}

.prev {
    left: 10px;

}

.next {
    right: 10px;
}



.swipe_carding {
    position: absolute;
    width: 100%;
    height: 100%;
    background-color: rgba(240, 240, 240, 0); 
    z-index: 10; /* Поверх изображения */
    top: 0; /* Располагаем поверх изображения */
    left: 0;
    overflow: hidden;
    pointer-events: auto;
  }
  
  .swipe_image {
    /* position: absolute; */
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%) scale(1);
    transition: transform 0.3s ease;
    max-width: 100vw;
    height: 100vw;
  }


  .name_age {
    display: flex !important;
    justify-content: flex-start !important; /* Распределение элементов по всей ширине */
    align-items: center !important; /* Выравнивание по вертикали */
    width: 100% !important; /* Ширина блока */
    gap: 30px !important; /* Добавляем расстояние между элементами */
}

.profile_bio_div {
    max-width: 650px;
    /* display: flex; */
    /* justify-content: center; */
    background-color: #49494938;
    border-radius: 15px;
    /* align-items: center; */
    /* flex-direction: column; */
    margin-top: 15px;
    padding-top: 5px;
    padding-bottom: 25px;
    margin-bottom: 15px;
}

.profile_bio {
    font-size: 1.2rem;
    font-weight: 500;
    text-shadow: 1px 1px 3px rgba(95, 93, 93, 0.153);
    color: var(--text-color);
    padding-left: 20px; /* Внутренний отступ для текста */
    padding-top: 3px;
    text-align: left; /* Выравнивание текста по левому краю */
    display: flex;
    flex-direction: column;
    justify-content: flex-start; /* Описание начинается с верха блока */
    align-items: stretch ; /* Элементы растягиваются на всю ширину */
    gap: 3px;
    position: relative;
}

.bio_card {
    margin-top: 1px; /* Отступ сверху */
    text-shadow: 1px 1px 3px rgba(95, 93, 93, 0.153);
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
    padding: 1px ; /* Внутренний отступ для текста */
    border-radius: 1px; /* Скругляем углы */
    /* background-color: var(--background-color); Фон для карточки bio */
    max-width: 450px; /* Ограничиваем максимальную ширину */
    width: 99% ;
    
    
}

.bio_card p {
    margin: 5px 0 ; /* Отступы между элементами */
    font-size: 1rem ;
    color: var(--text-color);
    padding-left: 10px;
    padding-right: 10px;
    /* border-bottom: 1px solid white;
    border-top: 1px solid #ffffff; */
    width: 100vw;
    display: flex;
    padding-top: 10px;
    padding-bottom: 10px;
}

.full_bio{
    width: 100vw;
    height: 100vh;
    z-index: 7;
    margin-top: 10px;
    max-width: 450px;
}

.likes_bar{
    position: relative;
    display: flex;
    justify-content: space-evenly;
    /* margin-left: 50px; */
    /* margin-right: 50px; */
    width: 98vw;
    height: 19px;
    max-width: 450px;
    background-color: var(--background-color-search-bar);
    /* border: 4px solid #b3b2b2; */
    border-radius: 6px;
    z-index:    2;
    outline: none; /* Убирает синий квадрат */
  -webkit-tap-highlight-color: transparent;
}

.like_bar{
    position: absolute;
    right: 15%;
    top: -150%;
    background-color: #f0f8ff00;
    border: 0;
    outline: none; /* Убирает синий квадрат */
  -webkit-tap-highlight-color: transparent;
}
.like_bar:hover {
    background-color: transparent; /* Фон остается прозрачным при наведении */
}

.dislike_bar{
    position: absolute;
    left: 15%;
    top: -150%;
    background-color: #f0f8ff00;
    border: 0;
    outline: none; /* Убирает синий квадрат */
  -webkit-tap-highlight-color: transparent;
}
.dislike_bar:hover {
    background-color: transparent; /* Фон остается прозрачным при наведении */
}

.bar_img{
    width: 66px;
    box-shadow: 3px 3px 8px rgba(0, 0, 0, 0.5);
    border-radius: 50%;
    outline: none; /* Убирает синий квадрат */
  -webkit-tap-highlight-color: transparent;
}

.i_bar{
    position: absolute;
    /* left: 15%; */
    top: -67%;
    background-color: #f0f8ff00;
    border: 0;
    outline: none; /* Убирает синий квадрат */
  -webkit-tap-highlight-color: transparent;
}
.i_bar:hover {
    background-color: transparent; /* Фон остается прозрачным при наведении */
}

.bar_i_img{
    width: 43px;
    box-shadow: 3px 3px 8px rgba(0, 0, 0, 0.5);
    border-radius: 50%;
    outline: none; /* Убирает синий квадрат */
  -webkit-tap-highlight-color: transparent;
}

.VIP_bar{
    position: absolute;
    left: 66%;
    top: 4%;
    background-color: #f0f8ff00;
    border: 0;
    outline: none; /* Убирает синий квадрат */
  -webkit-tap-highlight-color: transparent;
}
.VIP_bar:hover {
    background-color: transparent; /* Фон остается прозрачным при наведении */
}

.VIP_img{
    width: 103px;
    height: 103px;
    box-shadow: 3px 3px 8px rgba(0, 0, 0, 0.5);
    border-radius: 50%;
    animation: spin 4s infinite linear; /* Анимация вращения */
    outline: none; /* Убирает синий квадрат */
  -webkit-tap-highlight-color: transparent;
}

.indicators{
    margin-top: 0;
    display: flex;
    position: absolute;
    justify-content: center;
    z-index: 1;
    left: 50%;
    transform: translateX(-50%);
    top: 5px;
}


.profile_bio_div2 {
    max-width: 650px;
    /* display: flex; */
    /* justify-content: center; */
    /* background-color: #49494938; */
    background: linear-gradient(to bottom, rgba(255, 255, 255, 0), rgba(0, 0, 0, 1));
    border-radius: 5px;
    /* align-items: center; */
    /* flex-direction: column; */
    margin-top: 15px;
    padding-top: 5px;
    padding-bottom: 25px;
    /* margin-bottom: 15px; */
    z-index: 1;
    width: 100%;
    position: absolute;
    bottom: 7px;
    left: 0.1%;
    transform: translateY(11%);
}

.profile_bio2 {
    font-size: 1rem;
    font-weight: 200;
    text-shadow: 1px 1px 3px rgba(95, 93, 93, 0.153);
    color:white;
    padding-left: 20px; /* Внутренний отступ для текста */
    padding-top: 3px;
    padding-bottom: 7px;
    text-align: left; /* Выравнивание текста по левому краю */
    display: flex;
    flex-direction: column;
    justify-content: flex-start; /* Описание начинается с верха блока */
    align-items: stretch ; /* Элементы растягиваются на всю ширину */
    /* gap: 3px; */
    position: relative;
}

.VIP_bar2{
    position: absolute;
    left: 75%;
    top: 10%;
    background-color: #f0f8ff00;
    border: 0;
    outline: none; /* Убирает синий квадрат */
  -webkit-tap-highlight-color: transparent;
}
.VIP_bar2:hover {
    background-color: transparent; /* Фон остается прозрачным при наведении */
}

.VIP_img2{
    width: 63px;
    height: 63px;
    box-shadow: 3px 3px 8px rgba(0, 0, 0, 0.5);
    border-radius: 50%;
    animation: spin 4s infinite linear; /* Анимация вращения */
    outline: none; /* Убирает синий квадрат */
  -webkit-tap-highlight-color: transparent;
}


.x_bar{
    position: absolute;
    left: 44%;
    top: -17%;
    background-color: #f0f8ff00;
    border: 0;
    outline: none; /* Убирает синий квадрат */
  -webkit-tap-highlight-color: transparent;
}

.x_bar:hover {
    background-color: transparent; /* Фон остается прозрачным при наведении */
}

.bar_x_img{
    width: 43px;
    box-shadow: 3px 3px 8px rgba(0, 0, 0, 0.5);
    border-radius: 50%;
    outline: none; /* Убирает синий квадрат */
  -webkit-tap-highlight-color: transparent;
}


@keyframes spin {
    0% {
      transform: rotate(-19deg); /* Начальная позиция — без поворота */
    }
    50% {
      transform: rotate(6deg); /* Вращение на 180 градусов влево */
    }
    100% {
      transform: rotate(-19deg); /* Возвращение в исходное положение */
    }
  }

  .swipe_carding2 {
    position: absolute;
    width: 100%;
    height: 100%;
    background-color: rgba(240, 240, 240, 0); 
    z-index: 1; /* Поверх изображения */
    top: 0; /* Располагаем поверх изображения */
    left: 0;
    overflow: hidden;
    pointer-events: auto;
    -webkit-appearance: none;
appearance: none;
-webkit-tap-highlight-color: rgba(0,0,0,0); /* Полностью убираем выделение */
touch-action: manipulation;
  }

  .swipe_image2 {
    /* position: absolute; */
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%) scale(1);
    transition: transform 0.3s ease;
    max-width: 100vw;
    height: 100vw;
    outline: none; /* Убирает синий квадрат */
  -webkit-tap-highlight-color: transparent;
  -webkit-appearance: none;
appearance: none;
-webkit-tap-highlight-color: rgba(0,0,0,0); /* Полностью убираем выделение */
touch-action: manipulation;
  }


/*__________________ ЕСЛИ МАТЧ СОВПАЛ______________________ */

.match_notification {
    align-items: center;
    /* background-color: #fff; */
    border: 2px solid #ccc;
    box-shadow: 0 4px 8px #0000001a;
    display: flex;
    flex-direction: column;
    height: 100vh;
    left: 50%;
    padding: 20px;
    position: fixed;
    text-align: center;
    top: 50%;
    transform: translate(-50%, -50%);
    width: 93vw;
    z-index: 1000;
    padding-top: 10%;
    padding-left: 15px;
    padding-right: 15px;
    margin-top: 20%;
}

.match_notification img {
    border-radius: 5%;
    max-width: 70vw;
}

.match_notification button {
  margin-top: 10px;
  padding: 8px 16px;
  border: none;
  background-color: #ff4757;
  color: white;
  cursor: pointer;
  border-radius: 4px;
  font-size: 1.1rem;
}

.match_text_div{
    margin: 0;
    padding: 20px;
    background-color: #bebcbca8;
    border-radius: 13px;
    box-shadow: 0 4px 8px #505050;

}



.match_button_div{
    display: flex;
    gap: 40px;
}