.profile_container {
    text-align: center;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center; /* Центрируем карточки по горизонтали */
    gap: 5px; /* Отступ между карточками */
    padding-top: 10px;
    margin-bottom: 20px; /* Добавляем больше пространства снизу */
}


.profile {
    margin-bottom: 1px ; /* Увеличиваем отступ снизу */
    display: flex;
    flex-direction: column ; /* Выстраиваем фото и описание вертикально */
    width: 99% ; /* Задаем ширину карточки */
    max-width: 450px;
    /* aspect-ratio: 3 / 4 !important; Соотношение сторон 3:4 */
    border-radius: 1px ; /* Скругляем углы */
    /* overflow: hidden !important; Скрываем части, которые выходят за границы */
    background-color:  var(--background-color) ; /* Фон карточки */
}

.img {
    /* position: relative !important; */
    width: 100%;
    /* height: 75% !important; 75% высоты отводим под фото */
    position: relative;
    aspect-ratio: 3 / 4;

}

.styled_image {
    width: 100% ;
    height: 100% ;
    object-fit: cover; /* Масштабируем изображение, сохраняя пропорции и центрирование */
    object-position: center; /* Центрируем фото внутри карточки */
}

.bio_card {
    margin-top: 1px ; /* Отступ сверху */
    text-shadow: 1px 1px 3px rgba(95, 93, 93, 0.153);
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
    padding: 1px ; /* Внутренний отступ для текста */
    border-radius: 1px ; /* Скругляем углы */
    background-color:  var(--background-color); /* Фон для карточки bio */
    max-width: 450px; /* Ограничиваем максимальную ширину */
    width: 99%;
}

.bio_card p {
    margin: 5px 0; /* Отступы между элементами */
    font-size: 1rem ;
    color: var(--text-color);
    padding-left: 10px;
    padding-right: 10px;
}

.name_age {
    display: flex;
    justify-content: flex-start; /* Распределение элементов по всей ширине */
    align-items: center; /* Выравнивание по вертикали */
    width: 100%; /* Ширина блока */
    gap: 30px; /* Добавляем расстояние между элементами */
}

.profile_bio {
    font-size: 1.2rem;
    font-weight: 500;
    text-shadow: 1px 1px 3px rgba(95, 93, 93, 0.153);
    color: var(--text-color);
    padding-left: 20px; /* Внутренний отступ для текста */
    padding-top: 10px;
    text-align: left; /* Выравнивание текста по левому краю */
    display: flex;
    flex-direction: column;
    justify-content: flex-start; /* Описание начинается с верха блока */
    align-items: stretch; /* Элементы растягиваются на всю ширину */
    gap: 15px;
}

.btn_profile button {
    font-weight: bold; 
    border-radius: 5px; /* Скругленные углы */
    padding: 10px; /* Внутренний отступ для кнопки */
    background-color: #ff4081; /* Цвет кнопки */
    color: white; /* Цвет текста кнопки */
    border: none; /* Убираем рамку */
    cursor: pointer; /* Изменение курсора на pointer */
    margin-top: 20px; /* Отступ сверху для размещения кнопки ниже полей */
    font-size: 1rem;
}

.btn_profile button:hover {
    background-color: #e63974; /* Цвет кнопки при наведении */
    transform: translateY(-3px); /* Плавный подъем кнопки при наведении */
}

.btn_profile button:active {
    background-color: #c72b5f; /* Цвет кнопки при клике */
    transform: translateY(0);
}

.btn_profile button:focus {
    outline: none;
}





.prev, .next {
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    background-color: rgba(0, 0, 0, 0.5);
    color: white;
    border: none;
    padding: 10px;
    padding-left: 15px;
    padding-right: 15px;
    cursor: pointer;
    border-radius: 50px;
}

.prev {
    left: 10px;
}

.next {
    right: 10px;
}



