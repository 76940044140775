/* Стили для иконки пользователя */
.user_icon {
    width: 60px;
    height: 60px;
    border-radius: 40%;
    object-fit: cover;
    margin-right: 10px;
  }
  
  /* Общий стиль списка чатов */
  .chat_list {
    margin-top: 0;
    max-width: 450px;
  }
  
  /* Стиль для каждого чата */
  .chat_item {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 2px;
    /* border: 2px solid rgb(143, 139, 139); */
    border-radius: 25px;
    background-color: rgb(14 14 14 / 72%);
    margin: 4px 4px;
  }
  
  /* Стиль для непрочитанных сообщений */
  .chat_item.unread {
    font-size: 700;
    color: #ff0000;
  }
  
  /* Стиль для содержимого чата */
  .chat_content {
    display: flex;
    flex-direction: column;
    flex: 1; /* Дает контенту максимальное пространство */
  }
  
  /* Стиль для верхней части сообщения (имя и сообщение) */
  .chat_header {
    display: flex;
    flex-direction: column;
    margin-left: 6px;
  }
  
  /* Стиль для имени пользователя */
  .chat_name {
    font-weight: bold;
    font-size: 1rem;
    margin-bottom: 5px;
    color: #da446c;
    
  }
  
  /* Стиль для последнего сообщения */
  .last_message {
    font-size: 0.9rem;
    color: #dfdfdf;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    /* padding-left: 25px; */
    padding-right: 10px;
    white-space:normal;
    display: -webkit-box; /* Используется для создания многоточия */
    line-height: 1.2em; /* Высота строки */
    max-height: 2.4em; /* Высота двух строк */ /* Обрезать текст после 2 строк */
    -webkit-box-orient: vertical; /* Вертикальная ориентация */
    word-break: break-word; /* Перенос длинных слов на новую строку */

  }
  
  /* Стиль для времени сообщения */
  .chat_time {
    font-size: 0.8rem;
    text-align: center;
    margin-bottom: 5px;
  }
  
  /* Стиль для даты сообщения */
  .chat_date {
    font-size: 0.8rem;
    text-align: right;
  }
  
  /* Обеспечивает одинаковую высоту элементов и центрирует по вертикали */
  .chat_item {
    display: flex;
    align-items: center;
  }
  
  .chatdt {
    display: flex;
    flex-direction: column;
    margin-right: 10px;
    color:#919191;
  }

  .chat_point_read {
    display: inline-block; /* Чтобы span мог быть стилизован как блок */
    width: 15px;
    height: 15px;
    background-color: rgba(128, 128, 128, 0); /* Цвет точки для прочитанных сообщений */
    border-radius: 50%; /* Делаем из квадрата круг */
    margin-right: 5px; /* Отступ справа, если нужно */
}

.unread {
    background-color: #0d62c7eb; /* Цвет для непрочитанных сообщений */
}

.back{
  display: flex;
  flex-direction: row-reverse;
  margin-bottom: 5px;
  width: 95vw;
  max-width: 450px;
}
.btn_prev_2{
  border-radius: 50%;
  background-color: #8e919187;
  z-index: 45;
  color: white;
  width: 20px;
  height: 20px;
  border: 0;
  padding: 5px;
  display: flex;
  justify-content: center;
  align-items: center;
  text-decoration: none;
}
