/* Основной контейнер для компонента */
.crop_container {
    position: relative;
    width: 100%;
    height: 400px; /* Можно изменить высоту по своему усмотрению */
    background-color: #333; /* Цвет фона */
    margin-top: 46px;
    border-radius: 10px; /* Закругленные углы */
    overflow: hidden; /* Скрытие частей изображения за пределами контейнера */
  }
  
  .cropcontainer {
    display: flex;
    flex-direction: column; /* Выстраиваем элементы по вертикали */
    align-items: center;    /* Центрируем элементы по горизонтали (если нужно) */
  }
  
  .btn_profile {
    margin-top: 20px; /* Отступ сверху для кнопки, чтобы она была под кропом */
    display: flex;
    justify-content: center;
  }
  
  
  /* Стили для элемента ввода файла */
  input[type="file"] {
    padding: 10px;
    margin-top: 50px;
    display: block;
    font-size: 16px;
  }
  
  /* Уменьшение кнопки подтверждения на мобильных устройствах */
  @media (max-width: 600px) {
    .btn_profile button {
      padding: 8px 16px;
      font-size: 14px;
    }
  
    .crop_container {
      height: 300px; /* Меньшая высота для мобильных устройств */
    }
  }
  
  .cropped img{
    width: 99%;
    /* height: 100vh; */
  }

  .btn_profile button {
    font-weight: bold;
    background-color: #ff4080cc !important; /* Основной цвет кнопки */
    color: white !important;
    border: none !important;
    padding: 7px 70px !important; /* Размер кнопки */
    font-size: 1rem !important; /* Размер текста */
    border-radius: 5px !important; /* Скругленные углы */
    cursor: pointer !important; /* Меняем курсор на указатель при наведении */
    box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.1) !important; /* Тень кнопки */
    transition: background-color 0.3s ease, transform 0.3s ease !important;
    margin: 30px 5px !important; /* Отступы между кнопками */
}

.btn_profile button:hover {
    background-color: #e63974 !important; /* Цвет кнопки при наведении */
    transform: translateY(-3px) !important; /* Плавный подъем кнопки при наведении */
}

.btn_profile button:active {
    background-color: #c72b5f !important; /* Цвет кнопки при клике */
    transform: translateY(0) !important;
}

.btn_profile button:focus {
    outline: none !important;
}
