
.slider {
  position: relative;
  width: 70vw;
  height: 6px;
  background-color: #817e7e;
  margin: 30px;
  max-width: 450px;
}

.slider-track {
  position: absolute;
  height: 100%;
  background-color: #ff4081;
  z-index: 1;
}

.thumb {
  position: absolute;
  top: 50%;
  width: 30px;
  height: 30px;
  background-color: #ff4081;
  border-radius: 50%;
  border: 2px solid white;
  /* transform: translate(-50%, -50%); */
  z-index: 2;
  cursor: pointer;
  touch-action: none;
}

.thumb-min {
  transform: translate(-70%, -50%);
  touch-action: none;
  left: 0;
}

.thumb-max {
  transform: translate(-20%, -50%);
  touch-action: none;
  left: 100%;
}