.likers_container {
    display: grid;
    grid-template-columns: repeat(3, 1fr); /* 3 столбца одинаковой ширины */
    gap: 5px; /* Отступы между карточками */
    padding: 10px;
    
}


@media screen and (min-width: 650px) {
    /* Стили для экранов шире 650px */
    .likers_container {
        display: grid;
        grid-template-columns: repeat(auto-fit, minmax(160px, 1fr));
        gap: 5px; /* Отступы между карточками */
        padding: 10px;
        
    }
  }


.likers {
    position: relative;
    background-color: #f9f9f9;
    border: 1px solid #ccc; /* Рамка 1px вокруг карточки */
    border-radius: 10px;
    cursor: pointer; /* Добавляем указатель, чтобы карточка выглядела как кнопка */
    overflow: hidden; /* Скрываем излишки фото, если они выходят за рамки карточки */
    display: flex;
    justify-content: center; /* Центрируем содержимое по горизонтали */
    align-items: center; /* Центрируем содержимое по вертикали */
    max-width: 150px;
    aspect-ratio: 3 / 4;
   
}

.likers img {
    filter: blur(7px);
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    object-fit: cover; /* Масштабируем изображение по карточке, обрезая по наименьшей стороне */
    object-position: center; /* Центрируем изображение */
}

.likers p {
    position: absolute;
    bottom: 0;
    left: 0;
    width: 100%;
    background-color: rgba(0, 0, 0, 0.3); /* Полупрозрачный фон для текста */
    color: white;
    margin: 0;
    padding: 1px;
    text-align: center;
    font-size: 1.2rem;
    z-index: 10; /* Устанавливаем текст над изображением */
}

.wholike {
    margin: 50px auto 0 !important;
    text-align: center !important; /* Центрируем текст по горизонтали */
    color: var(--text-color);
}

.back{
    display: flex;
    flex-direction: row-reverse;
    margin-bottom: 5px;
    width: 95vw;
    max-width: 450px;
  }
  .btn_prev_2{
    border-radius: 50%;
    background-color: #8e919187;
    z-index: 45;
    color: white;
    width: 20px;
    height: 20px;
    border: 0;
    padding: 5px;
    display: flex;
    justify-content: center;
    align-items: center;
    text-decoration: none;
  }
  