


.head_link {
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.skip {
    margin: 43px 15px 0;
    font-size: 1.4rem;
    color: var(--text-color);
    text-decoration: none;
}

.skip_div{
    display: flex;

}

.push {
    width: 100vw;
    position: absolute;
    top: 30%;
}

.start_text{
    display: flex;
    flex-direction: column;
    text-align: center;
    justify-content: center;
    width: 60%;
    color: white;
    font-size: 3rem;
    font-weight: 400;
    text-shadow: 2px 2px 4px rgb(0 0 0 / 77%);
    margin-bottom: 10px;


}

.finish {
    width: 100vw;
    position: absolute;
    top: 60%;
    display: flex;
    flex-direction: column; /* Располагаем элементы вертикально */
    align-items: center; /* Выравниваем элементы по центру */
    justify-content: center; /* Центрируем элементы по вертикали */
}

.finish_link {
    font-size: 2.4rem;
    margin: 0 0 0 0;
    font-weight: 800;
    color: #fb265d;
    text-shadow: 2px 2px 5px #0000005f;
    text-decoration: none;
    z-index: 35;
    pointer-events: auto;

}

.training_area_start {
    width: 95%;
    height: 95%;
    position: absolute;
    z-index: 20;
    background-color: #0000008c; /* Прозрачный фон тренировки */
    left: 2.5%;
    top: 2.5%;
    border-radius: 20px;
    pointer-events: none;
    display: flex;
    justify-content: center;
    align-items: center; /* Вертикальное выравнивание по центру */
    flex-direction: column; /* Размещаем элементы по вертикали */
}

.training_area {
    width: 95%;
    height: 95%;
    position: absolute;
    z-index: 20;
    background-color: #0000008c; /* Прозрачный фон тренировки */
    left: 2.5%;
    top: 2.5%;
    border-radius: 20px;
    pointer-events: none;
    display: flex;
    justify-content: flex-end;
    align-items: center; /* Вертикальное выравнивание по центру */
    flex-direction: column; /* Размещаем элементы по вертикали */
}

.training_area_text {
    display: flex;
    flex-direction: column;
    text-align: center;
    justify-content: center;
    width: 60%;
    color: white;
    font-size: 2rem;
    font-weight: 200;
    text-shadow: 2px 2px 4px rgb(0 0 0 / 77%);
    margin-bottom: 10px;
}

.swipe_icon {
    height: 30%;
    width: 30%;
}

.training_area_expanded {
    width: 95%;
    height: 60vh;
    position: absolute;
    z-index: 25;
    background-color: #9290908c;
    left: 2.5%;
    top: -2px;
    border-radius: 20px;
    pointer-events: none;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
}

.training_area_text_expanded {
    display: flex;
    flex-direction: column;
    text-align: center;
    justify-content: center;
    
    color: white;
    font-size: 3rem !important;
    font-weight: 300;
    text-shadow: 2px 2px 4px rgb(0 0 0 / 77%);
}




.swipe_icon_expanded {
    height: 30%;
    width: 30%;
}

.training_area_push {
    width: 95%;
    height: 95%;
    position: absolute;
    z-index: 20;
    background-color: #0000008c; /* Прозрачный фон тренировки */
    left: 2.5%;
    top: 2.5%;
    border-radius: 20px;
    pointer-events: none;
    display: flex;
    justify-content: center;
    align-items: center; /* Вертикальное выравнивание по центру */
    flex-direction: column; /* Размещаем элементы по вертикали */
}

.training_area_text_push {
    margin-top: 77%;
    display: flex;
    flex-direction: column;
    text-align: center;
    justify-content: center;
    width: 60%;
    color: white;
    font-size: 1.8rem;
    font-weight: 200;
    line-height: 0.9;
    /* font: small-caption; */
    text-shadow: 2px 2px 4px rgb(0 0 0 / 77%);
}


.training_area_finish {
    width: 95%;
    height: 95%;
    position: absolute;
    z-index: 20;
    background-color: #0000008c; /* Прозрачный фон тренировки */
    left: 2.5%;
    top: 2.5%;
    border-radius: 20px;
    pointer-events: none;
    display: flex;
    justify-content: center;
    align-items: center; /* Вертикальное выравнивание по центру */
    flex-direction: column; /* Размещаем элементы по вертикали */
}

.training_area_text_finish {
    margin-bottom: 10%;
    display: flex;
    flex-direction: column;
    text-align: center;
    justify-content: center;
    width: 60%;
    color: white;
    /* font: small-caption; */
    text-shadow: 2px 2px 4px rgb(0 0 0 / 77%);
    font-size: 1.4rem;
    font-weight: 300;
    line-height: 1.1;
}



.profile_container {
    text-align: center;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center; /* Центрируем карточки по горизонтали */
    gap: 5px; /* Отступ между карточками */
    padding-top: 10px;
    margin-bottom: 20px; /* Добавляем больше пространства снизу */
}


.profile {
    margin-bottom: 1px ; /* Увеличиваем отступ снизу */
    display: flex;
    flex-direction: column ; /* Выстраиваем фото и описание вертикально */
    width: 99% ; /* Задаем ширину карточки */
    max-width: 450px;
    /* aspect-ratio: 3 / 4 !important; Соотношение сторон 3:4 */
    border-radius: 1px !important; /* Скругляем углы */
    /* overflow: hidden !important; Скрываем части, которые выходят за границы */
    background-color:var(--background-color); /* Фон карточки */
}

.img {
    /* position: relative !important; */
    width: 100% !important;
    /* height: 75% !important; 75% высоты отводим под фото */
    position: relative;

}

.styled_image {
    width: 100%;
    height: 100% ;
    object-fit: cover; /* Масштабируем изображение, сохраняя пропорции и центрирование */
    object-position: center; /* Центрируем фото внутри карточки */
}

.bio_card {
    margin-top: 1px; /* Отступ сверху */
    text-shadow: 1px 1px 3px rgba(95, 93, 93, 0.153);
    display: flex ;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
    padding: 1px ; /* Внутренний отступ для текста */
    border-radius: 1px ; /* Скругляем углы */
    background-color:var(--background-color); /* Фон для карточки bio */
    max-width: 450px ; /* Ограничиваем максимальную ширину */
    width: 99%;
}

.bio_card p {
    margin: 5px 0; /* Отступы между элементами */
    font-size: 1rem;
    color: var(--text-color);
    padding-left: 10px;
    padding-right: 10px;
}

.name_age {
    display: flex ;
    justify-content: flex-start; /* Распределение элементов по всей ширине */
    align-items: center ; /* Выравнивание по вертикали */
    width: 100%; /* Ширина блока */
    gap: 30px ; /* Добавляем расстояние между элементами */
}

.profile_bio {
    font-size: 1.2rem;
    font-weight: 500;
    text-shadow: 1px 1px 3px rgba(95, 93, 93, 0.153);
    color: var(--text-color);
    padding-left: 20px ; /* Внутренний отступ для текста */
    padding-top: 10px;
    text-align: left ; /* Выравнивание текста по левому краю */
    display: flex ;
    flex-direction: column ;
    justify-content: flex-start; /* Описание начинается с верха блока */
    align-items: stretch ; /* Элементы растягиваются на всю ширину */
    gap: 15px;
}

.btn_profile button {
    font-weight: bold; 
    border-radius: 5px !important; /* Скругленные углы */
    padding: 10px; /* Внутренний отступ для кнопки */
    background-color: #ff4081; /* Цвет кнопки */
    color: white; /* Цвет текста кнопки */
    border: none; /* Убираем рамку */
    cursor: pointer; /* Изменение курсора на pointer */
    margin-top: 20px; /* Отступ сверху для размещения кнопки ниже полей */
    font-size: 1rem;
}

.btn_profile button:hover {
    background-color: #e63974 !important; /* Цвет кнопки при наведении */
    transform: translateY(-3px) !important; /* Плавный подъем кнопки при наведении */
}

.btn_profile button:active {
    background-color: #c72b5f !important; /* Цвет кнопки при клике */
    transform: translateY(0) !important;
}

.btn_profile button:focus {
    outline: none !important;
}







/* ------------------ОТ ПОИСКА */
.profile_container {
    position: fixed;
    text-align: center;
    display: flex;
    flex-direction: column;
    /* justify-content: center; */
    align-items: center; /* Центрируем карточки по горизонтали */
    gap: 5px; /* Отступ между карточками */
    padding-top: 3px;
    margin-bottom: 20px; /* Добавляем больше пространства снизу */
    width: 100vw;
    
}


.profile {
    margin-bottom: 1px ; /* Увеличиваем отступ снизу */
    display: flex;
    flex-direction: column ; /* Выстраиваем фото и описание вертикально */
    width: 99% ; /* Задаем ширину карточки */
    max-width: 450px;
    /* aspect-ratio: 3 / 4 !important; Соотношение сторон 3:4 */
    border-radius: 1px ; /* Скругляем углы */
    /* overflow: hidden !important; Скрываем части, которые выходят за границы */
    background-color: var(--background-color); /* Фон карточки */
}




.prev, .next {
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    background-color: rgba(0, 0, 0, 0.438);
    color: rgb(198, 197, 197);
    border: none;
    cursor: pointer;
    border-radius: 50px;
    
    z-index: 15;
    width: 40px;
    height: 40px;
    pointer-events: auto;
    padding-top: 7px;
    padding-bottom: 7px;
}

.prev {
    left: 10px;

}

.next {
    right: 10px;
}



.swipe_carding {
    position: absolute;
    width: 100%;
    height: 100%;
    background-color: rgba(240, 240, 240, 0); 
    z-index: 10; /* Поверх изображения */
    top: 0; /* Располагаем поверх изображения */
    left: 0;
    overflow: hidden;
    pointer-events: auto;
  }
  
  .swipe_image {
    /* position: absolute; */
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%) scale(1);
    transition: transform 0.3s ease;
    max-width: 100vw;
    height: 50vh;
  }


  .name_age {
    display: flex;
    justify-content: flex-start; /* Распределение элементов по всей ширине */
    align-items: center; /* Выравнивание по вертикали */
    width: 100%; /* Ширина блока */
    gap: 30px; /* Добавляем расстояние между элементами */
}

.profile_bio {
    font-size: 1.2rem;
    font-weight: 500;
    text-shadow: 1px 1px 3px rgba(95, 93, 93, 0.153);
    color: var(--text-color);
    padding-left: 20px; /* Внутренний отступ для текста */
    padding-top: 10px;
    text-align: left; /* Выравнивание текста по левому краю */
    display: flex;
    flex-direction: column;
    justify-content: flex-start; /* Описание начинается с верха блока */
    align-items: stretch; /* Элементы растягиваются на всю ширину */
    gap: 15px;
}

.bio_card {
    margin-top: 1px; /* Отступ сверху */
    text-shadow: 1px 1px 3px rgba(95, 93, 93, 0.153);
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
    padding: 1px; /* Внутренний отступ для текста */
    border-radius: 1px; /* Скругляем углы */
    background-color: var(--background-color); /* Фон для карточки bio */
    max-width: 450px; /* Ограничиваем максимальную ширину */
    width: 99% ;
    
    
}



.full_bio{
    width: 100vw;
    height: 100vh;
    z-index: 7;
    margin-top: 10px;
    /* filter: blur(5px); */
}

@media screen and (max-width: 394px) {
    .start_text{
        font-size: 2rem;
        
    }

    .training_area_text {
        font-size: 1.5rem;   
    }


}