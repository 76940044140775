.verify_container{
    align-items: center;
    display: flex;
    flex-direction: column;
    justify-content: center;
    position: absolute;
    top: 50%;
    left: 50%;
    translate: -50% -50%;
    min-width: 280px;
    min-height: 351px;
    text-align: center;
}

.verify_container form{
    
    align-items: center;
    display: flex;
    flex-direction: column;
    width: 100%;
    gap: 24px;
    background-color: #646262;
    color: white;
    padding: 15px;
    border-radius: 10px;
    min-height: 100px;
}

.verify_container label{
    display: flex;
    flex-direction: column;
    gap: 10px;
    margin-top: 15px;
}

.verify_button {
    background-color: #ff4081;
    border: none;
    border-radius: 5px ;
    color: #fff;
    cursor: pointer;
    font-size: 1rem;
    font-weight: 700;
    padding: 10px;
    width: 53%;
    box-shadow: 2px 1px 7px black;
}

.resend_button2{
    background-color: #ff4081;
    border: none;
    border-radius: 5px ;
    color: #fff;
    cursor: pointer;
    font-size: 1rem;
    font-weight: 700;
    padding: 10px;
    width: 53%;
    box-shadow: 2px 1px 7px black;
}