.create_placeholder {
    width: 100px;
    height: 135px;
    /* display: inline-block; */
    min-height: 1em;
    /* vertical-align: middle; */
    cursor: pointer;
    background-color: #7a828229;
    background-image: url('../default3.jpg');
    border-radius: 10px;
    font-weight: 700;
    display: flex;              /* Используем Flexbox */
    justify-content: center;    /* Горизонтальное центрирование */
    align-items: center;
    opacity: .9;
    border: 1px solid gray;
}

.create_photo_grid {
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    gap: 15px;
    width: 100%;
    row-gap: 25px;
}

.photo_card img {
    /* filter: blur(7px); */
    /* position: absolute; */
    top: 0;
    left: 0;
    width: 100px;
    height: 135px;
    border-radius: 10px;
    object-fit: cover; /* Масштабируем изображение по карточке, обрезая по наименьшей стороне */
    object-position: center; /* Центрируем изображение */
    border: 1px solid gray;
    
}

.create_edit_form {
    display: flex;
    min-height: 420px;
    align-items: center;
    padding: 20px;
    border-radius: 8px;
    box-shadow: 0 4px 10px rgba(0, 0, 0, 0.1);
    position: relative;
    justify-content: center;
}

.create_form_group{
    margin-bottom: 20px;
    display: flex;
    flex-direction: column;
}

.create_batton_x {

    position: absolute;
    background-image: url('../x.svg');
    background-color: unset;
    bottom: 0;
    right: 0;
    height: 25px;
    width: 25px;
    border: unset;
    background-repeat: no-repeat;
    padding: unset;
}

.photo_card {
    position: relative;
}

.btn_edit_container{
    display: flex;
    justify-content: center; /* Центрируем форму по горизонтали */
    align-items: center; /* Центрируем форму по вертикали */
    /* height: 100vh !important; Контейнер занимает всю высоту экрана */
    /* width: 100vh !important; */
}

.btn_edit button {
    font-weight: bold; 
    background-color: #ff4081 !important; /* Основной цвет кнопки */
    color: white !important; /* Цвет текста */
    border: none !important; /* Убираем границу */
    padding: 12px 24px !important; /* Размер кнопки */
    font-size: 1rem !important; /* Размер текста */
    border-radius: 5px !important; /* Скругленные углы */
    cursor: pointer !important; /* Меняем курсор на указатель при наведении */
    box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.1) !important; /* Тень кнопки */
    transition: background-color 0.3s ease, transform 0.3s ease !important; /* Плавная анимация */
    margin: 35px 5px 0 !important; /* Отступы между кнопками */
}

.btn_edit button:hover {
    background-color: #e63974 !important; /* Цвет кнопки при наведении */
    transform: translateY(-3px) !important; /* Плавный подъем кнопки при наведении */
}

.btn_edit button:active {
    background-color: #c72b5f !important; /* Цвет кнопки при клике */
    transform: translateY(0) !important; /* Кнопка возвращается на место при клике */
}

.btn_edit button:focus {
    outline: none !important; /* Убираем стандартную обводку фокуса */
}