.edit_container {
    display: flex;
    justify-content: center; /* Центрируем форму по горизонтали */
    align-items: center; /* Центрируем форму по вертикали */
}

.edit_form {
    display: flex;
    /* flex-direction: column; Выстраиваем элементы по вертикали */
    /* width: 100%; Задаем ширину формы */
    min-height: 420px;
    padding: 20px; /* Отступ внутри формы */
    border-radius: 8px; /* Скругляем углы */
    box-shadow: 0 4px 10px rgba(0, 0, 0, 0.1); /* Добавляем тень */
}

/* Стили для всех полей формы */
.form_group {
    margin-bottom: 20px;
    width: 270px;
}

.form_group label {
    display: block;
    margin-bottom: 8px; /* Расстояние между названием поля и самим полем */
    font-size: 1rem;
    color: white; /* Цвет текста */
}

.edit_form input, .edit_form select, .edit_form textarea {
    width: 100%; /* Поля занимают всю ширину */
    padding: 10px; /* Внутренний отступ для увеличения размеров полей */
    font-size: 1rem; /* Размер текста внутри полей */
    border: 1px solid #ccc; /* Граница вокруг полей */
    border-radius: 4px; /* Скругленные углы */
    box-sizing: border-box; /* Чтобы padding не влиял на ширину */
}

.edit_form textarea {
    resize: none; /* Убираем возможность вручную менять размер */
    overflow: hidden; /* Скрываем скролл */
    min-height: 60px; /* Минимальная высота поля */
    font-size: 1rem; /* Размер текста */
    padding: 10px; /* Отступы внутри */
    width: 100%; /* Ширина поля */
    box-sizing: border-box; /* Учитываем отступы в ширине */
}

/* Кнопка */
.edit_form button {
    justify-content: center; /* Центрируем кнопку по горизонтали */
    align-items: center; 
    font-weight: bold; 
    border-radius: 5px !important; /* Скругленные углы */
    padding: 10px; /* Внутренний отступ для кнопки */
    background-color: #ff4081; /* Цвет кнопки */
    color: white; /* Цвет текста кнопки */
    border: none; /* Убираем рамку */
    cursor: pointer; /* Изменение курсора на pointer */
    margin-top: 20px; /* Отступ сверху для размещения кнопки ниже полей */
    font-size: 1rem;
}

.edit_form button:hover {
    background-color: #e63974; /* Цвет при наведении на кнопку */
}

.edit_form p {
    color: red; /* Цвет текста для ошибок */
}


.btn_edit_container{
    display: flex;
    justify-content: center; /* Центрируем форму по горизонтали */
    align-items: center; /* Центрируем форму по вертикали */
    /* height: 100vh !important; Контейнер занимает всю высоту экрана */
    /* width: 100vh !important; */
}
.btn_edit button {
    font-weight: bold; 
    background-color: #ff4081 !important; /* Основной цвет кнопки */
    color: white !important; /* Цвет текста */
    border: none !important; /* Убираем границу */
    padding: 12px 24px !important; /* Размер кнопки */
    font-size: 1rem !important; /* Размер текста */
    border-radius: 5px !important; /* Скругленные углы */
    cursor: pointer !important; /* Меняем курсор на указатель при наведении */
    box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.1) !important; /* Тень кнопки */
    transition: background-color 0.3s ease, transform 0.3s ease !important; /* Плавная анимация */
    margin: 10px 5px !important; /* Отступы между кнопками */
}

.btn_edit button:hover {
    background-color: #e63974 !important; /* Цвет кнопки при наведении */
    transform: translateY(-3px) !important; /* Плавный подъем кнопки при наведении */
}

.btn_edit button:active {
    background-color: #c72b5f !important; /* Цвет кнопки при клике */
    transform: translateY(0) !important; /* Кнопка возвращается на место при клике */
}

.btn_edit button:focus {
    outline: none !important; /* Убираем стандартную обводку фокуса */
}