.home_container {
    display: flex;
    justify-content: center;
    background-image: url('./dall2.jpg'); /* Добавляем фоновое изображение */
    background-size: cover; /* Фон масштабируется с сохранением пропорций */
    background-position: 50% 0; /* Центрируем фон */
    background-attachment: fixed; /* Фон остается прикрепленным при прокрутке */
    background-repeat: no-repeat; /* Отключаем повторение фона */
    height: 100vh; /* Высота контейнера равна высоте экрана */
    width: 100vw; /* Ширина контейнера равна ширине экрана */
    color: white;
    text-shadow: 1px 1px 3px rgba(0, 0, 0, 0.6);
    border-radius: 0px;
    box-shadow: 0px 4px 12px rgba(0, 0, 0, 0.2);
    display: flex;
    flex-direction: column;  
    align-items: center; 
  }
  
.home_title {
    padding-top: 50px; /* Внутренние отступы: убрано большое значение padding сверху */
    padding-bottom: 30px;
    font-size: 3rem;
    margin-bottom:5px; /* Внешний отступ снизу, увеличен для большей дистанции от описания */
    font-weight: 700;
    text-align: center;
}

.home_description {
    font-weight: 400;
    font-size: 1.2rem;
    margin-bottom: 50px; /* Внешний отступ снизу, чтобы было больше пространства после описания */
    text-align: center;
    max-width: 700px;
    padding-left: 15px;
    padding-right: 15px;
}

  
.button_container {
    display: flex;
    justify-content: center; /* Центрирование по горизонтали */
    align-items: center; /* Центрирование по вертикали */
    height: 100vh; /* Высота контейнера равна высоте экрана */
    

}

.get_started_btn {
    font-size: 1.5rem;
    background-color: #5c5ba4;
    border: none;
    color: white; /* Цвет текста */
    border-radius: 10px;
    padding: 8px 16px;
      /* font-size: 14px; */
}

.get_started_btn:hover {
    background-color: #e63974;
}

  
  .features_section {
    margin-top: 60px;
    padding-top: 40px;
    border-top: 1px solid rgba(255, 255, 255, 0.2);
  }
  
  .feature {
    text-align: center;
    padding: 20px;
    background-color: rgba(255, 255, 255, 0.1);
    border-radius: 8px;
    margin-bottom: 20px;
  }
  
  .feature h3 {
    font-size: 1.8rem;
    margin-bottom: 15px;
  }
  
  .feature p {
    /* font-size: 1rem; */
    color: rgba(255, 255, 255, 0.8);
  }


.get_started {
  background-color: #403a3a;
  justify-content: space-evenly;
    background-color: #faebd700;
    display: flex;
    flex-direction: row;
    border-radius: 10px;

}


/* отключение отступа перед кнопками */
ul {  
    padding: 0;
}


.home_1 {
  background-color: #1c1c1ca3;
    border-radius: 20px;
    margin-left: 30px;
    margin-right: 30px;
    position: relative;
    translate: 0 -40%;
    top: 40%;
    padding-bottom: 20px;
    max-width: 650px;
}

.language_button{
  border-radius: 5px;
  background-color: #868686;
  color: #fff;
  padding: 5px;
  width: 100%;
  padding: 10px;
  text-align: left;
  border: none;
  cursor: pointer;
}

.language_menu{
  background-color: #fff;
  border: 1px solid #ccc;
  list-style: none;
  margin: 0;
  padding: 0;
  position: relative;
  top: -3px;
  z-index: 2;
  border-bottom-left-radius: 5px;
  border-bottom-right-radius: 5px;
}


.requizits{
  display: flex;
    flex-direction: column;
    position: absolute;
    left: 10px;
    bottom: 4px;
    font-size: 11px;
    background-color: #3b3b3b7a;
    padding: 7px;
    border-radius: 8px;
}
.ip{
  color: #eaeaea;
}

.footer_links {
  text-align: center;
  font-size: 11px;
}

.footer_link {
  color: #eaeaea;
  text-decoration: underline;
}


.separator {
  margin: 0 5px; /* Отступы вокруг разделителя */
  color: #eaeaea; /* Цвет разделителя */
}

.contactInfo {
  margin: 4px 0;
}

.contactInfo h3 {
  margin-bottom: 15px;
}

.contactItem {
  display: flex;
  align-items: center;
}

.icon {
  color: #ffffff;
  margin-right: 8px;
  font-size: 1.2rem;
}

.link {
  color: #eaeaea;
  text-decoration: none;
  font-weight: 600;
}

.link:hover {
  text-decoration: underline;
}
