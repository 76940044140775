/* Стили для navbar */

.navbar {
  padding-top: 6px;
  padding-bottom: 2px;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  position: fixed; /* Закрепляет навбар в верхней части экрана */
  top: 0; /* Устанавливает его на самый верх страницы */
  width: 100vw; /* Занимает всю ширину экрана */
  z-index: 1000; /* Устанавливает высокий приоритет для отображения поверх других элементов */
  background-color: var(--background-color-nav);   /*РЕАЛИЗАЦИЯ СВЕТЛОЙ ТЕМЫ */
  align-items: center;
  /* padding-left: 15px;
  padding-right: 25px; */
  border-radius: 0 0 10px 10px;
}
  
  
.navbar_links {
  list-style: none;
  display: flex;
  gap: 15px;
  margin: 0;
  padding: 0;
  padding-right: 10px;
  margin-top: 0;
  margin-bottom: 0;
  align-items: center;
}


.navbar_links a {
  color: #868686;
  /* color: #575757; */
  text-decoration: none;
  font-size: 30px;
  transition: color 0.3s ease;
}

.navbar_links a:hover {
  /* color: #c0416b; Цвет при наведении  */
  color: #ff4081;
}


/* .lovesekrets {
  width: 170px;
  height: 27px;
  display: flex;
justify-content: center; 
margin-left: 10px;

} */

.lovesekrets{
  color: #ff4081;
  font-family: Kalam, cursive;
  font-size: 1.7rem;
  font-weight: 900;
  margin-left: 10px;
  position: relative;
  margin-bottom: -6px;
}

.button {
  background-color: #868686;
  color: beige;
  border-radius: 5px;
  border: 0;
  padding: 8px 16px;
  font-size: 14px;
}

@media screen and (max-width: 380px) {
  /* Стили для экранов шире 650px */
  .button {
    background-color: #868686;
    color: beige;
    border-radius: 5px;
    border: 0;
    padding: 5px 5px;
    font-size: 12px;
    
  }

  .navbar_links a {
    color: #868686;
    /* color: #575757; */
    text-decoration: none;
    font-size: 20px;
    transition: color 0.3s ease;
  }

  .lovesekrets {
    width: 140px;
    height: 22px;
    display: flex;
  justify-content: center; /* Центрируем по горизонтали */
  
  }

  .lovesekrets{
    left: 10px;
    top: -12px;
  }

}