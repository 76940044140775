.all_component {
    height: 100vh;
    width: 100vh;
}



.dislike, .like {
    height: 50px !important;  /* Задать общий размер кнопки */
    width: 50px !important;   /* Задать общий размер кнопки */
    position: absolute !important;
    top: 50%;
    transform: translateY(-50%);
    background-color: rgba(248, 247, 247, 0);
    border: none;
    cursor: pointer;
    border-radius: 50px;
    display: flex;
    justify-content: center;
    align-items: center; /* Центрирование изображения внутри кнопки */
    padding: 0;
}

.like img, .dislike img {
    width: 100% !important;  /* Заставить изображение занять всю ширину кнопки */
    height: 100% !important; /* Заставить изображение занять всю высоту кнопки */
    object-fit: contain; /* Сохранить пропорции изображения */
}



.dislike {
    left: 10px;
}

.like {
    right: 10px;
}



  

/* ________________________________ */

.all_str_h1 {
    margin: 45px 15px 0 !important;
    text-align: left !important; /* Центрируем текст по горизонтали */
    color:var(--color-h1);
    font-size: 15px;
    padding-top: 1px;
    
}

.indicators {
    margin-top: 0;
    display: flex;
    justify-content: center;
}

.dot {
    height: 13px;
    width: 13px;
    margin: 0 5px;
    background-color: rgb(94, 94, 94);
    border-radius: 50%;
    display: inline-block;
}

.active_dot {
    background-color: lightgray;
    height: 14px;
    width: 14px;
}

p {
    margin: 0;
}

.loading {
    position: fixed; /* фиксированное положение */
    top: 50%; /* отступ сверху 50% */
    left: 50%; /* отступ слева 50% */
    transform: translate(-50%, -50%); /* центрируем элемент */
    font-size: 1.5em; /* размер шрифта */
    color: var(--text-color); /* цвет текста */
    font-weight: 300; /* жирный шрифт */
    text-align: center; /* выравнивание текста по центру */
  }


