.container{
  display: flex;
  /* justify-content: center; */
  align-items: center;
  flex-direction: column;
  /* background-color: #f5ec7bb3; */
  /* height: 100vh; */

}


.subscription_container {
  margin-top: 57px;
    padding: 10px;
    text-align: center;
    width: 92vw;
    display: flex;
    flex-direction: column;
    align-content: center;
    align-items: center;
  }
  
  .subscription_grid {
    grid-gap: 20px;
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    margin: 20px 0;
    max-width: 450px;
    justify-content: center;
    width: 100%;
  }
  
  /* .subscription_card {
    background-color: #fff021;
    border: 2px solid #ccc;
    border-radius: 10px;
    padding: 20px;
    transition: transform 0.3s ease, background-color 0.3s ease;
    cursor: pointer;
    box-shadow: 0 4px 8px #00000094;
  } */

  .subscription_card {
    /* width: 300px;
    height: 200px; */
    background: linear-gradient(55deg, #ffb300cc, #ffee6c, #ffa700);
    background-size: 200% 200%;
    animation: shinyEffect 3s infinite;
    border-radius: 10px;
    box-shadow: 0 4px 8px #00000094;
    border: 2px solid #ccc;
    padding: 12px;
    cursor: pointer;
    color: black;
  }

  .subscription_card h3{
    margin: 14px;
  }

  .price_card {
    font-size: 1.2rem;
    font-weight: 100;
  }

  .discount{
    font-size: 1rem;
    font-weight: 500;
    margin-top: 2px;
  }
  
  @keyframes shinyEffect {
    0% { background-position: 0% 50%; }
    50% { background-position: 100% 50%; }
    100% { background-position: 0% 50%; }
  }
  
  .subscription_card:hover {
    transform: scale(1.05);
    background-color: #f0f0f0;
  }
  
  .subscription_details {
    background: linear-gradient(55deg, #ffb300cc, #ffee6c, #ffa700);
    background-size: 200% 200%;
    animation: shinyEffect 3s infinite;
    border: 2px solid #ddd;
    padding: 20px;
    border-radius: 10px;
  }

  .subscription_details p {
    font-size: 1.2rem;
  }
  
  .subscription_benefits {
    margin-top: 30px;
    text-align: left;
    display: flex;
    flex-direction: column;
    align-content: center;
    align-items: center;
  }
  
  .subscription_benefits h2 {
    margin-bottom: 10px;
  }
  
  .subscription_benefits ul {
    list-style-type: disc;
    margin: 0;
    padding-left: 20px;
  }
  
  .subscription_benefits li {
    margin: 5px 0;
  }
  
  .back{
    display: flex;
    flex-direction: row;
    margin-bottom: 5px;
    width: 95vw;
    max-width: 450px;
    margin-top: 1%;
    position: relative;
  }
  .btn_prev_2{
    border-radius: 50%;
    background-color: #8e919187;
    z-index: 45;
    color: white;
    width: 20px;
    height: 20px;
    border: 0;
    padding: 5px;
    display: flex;
    justify-content: center;
    align-items: center;
    text-decoration: none;
    position: absolute;
    right: 1%;
    top: 82%;
  }

  .VIP_div{
    height: 60px;
    position: relative;

  }

  .VIP_img2{
    -webkit-tap-highlight-color: transparent;
    animation: Subscription_spin 4s linear infinite;
    border-radius: 50%;
    filter: hue-rotate(3deg);
    height: 150px;
    left: 32%;
    outline: none;
    position: absolute;
    rotate: -9deg;
    top: -84%;
    /* width: 150px; */
    translate: -50%;

}

.subscription_details button{
  background-color: #ffbd1c;
    border-radius: 5px;
    font-size: 1rem;
    padding: 5px;
    padding-left: 15px;
    padding-right: 15px;
    margin-top: 15px;
}

.hasSubscription{
  margin-top: 57px;
    margin-bottom: -91px;
    text-align: center;
}