/* .chat_conteiner {
    display: flex;
    justify-content: center;

    display: flex;
    justify-content: center; 
    border: 2px solid var(--border-color);
    max-width: 450px;
    width: 99vw; 
    
    position: relative;
    left: 50vw;
    transform: translate(-50%, 0%); 
   overflow: hidden; 
     height: 100%;
    touch-action: none;
  }  */

  .messages {
    

    display: flex;
    flex-direction: column;
    height: calc(100vh - 100px);
    margin-top: 46px;
    overflow-y: auto;
    padding: 10px;
      }
  
  

  .message_input {
    bottom: 10px;
    display: flex;
    left: 0;
    padding-right: 20px !important;
    padding: 10px;
    position: fixed;
    right: 0;
}
  
  .message_input input {
    color: #4f4f4f;
    box-sizing: border-box;
    word-break: break-word;
    overflow-wrap: break-word;
    flex: 1 1;
    padding: 10px;
    font-size: 16px;
    border-bottom-left-radius: 30px;
    border-top-left-radius: 30px;
    border-top-right-radius: 30px;
    border-bottom-right-radius: 30px;
    background-color: #fffefe;
  }
  
  .message_input button {
    margin-left: 4px;
    padding: 10px 20px;
    font-size: 16px;
    font-weight: 800;
    color: rgb(255, 255, 255);
    border-bottom-left-radius: 30px;
    border-top-left-radius: 30px;
    border-top-right-radius: 30px;
    border-bottom-right-radius: 30px;
    /* background-color: #19b607; */
    background-color: #ff4081;
    border-color: #f9709ee4;
    border-width: 2px;
  }
  
  .message {
        /* Ваши существующие стили */
    max-width: 70%; /* Или любое другое значение */
    word-break: break-word;
    overflow-wrap: break-word;
    margin: 3px;
    padding: 5px;
    padding-left: 15px;
    padding-right: 15px;
    border-radius: 10px;
    max-width: 60%;
    color: #ffffffeb;
    /* background-color: ; */
  }
  
  .sent {
    background-color: #203053a1;
    align-self: flex-end;
  }
  
  .received {
    background-color: #203053a1;
    align-self: flex-start;
  }
  
  .timestamp {
    display: block;
    font-size: 10px;
    color: #c4c4c4;
    margin-top: 0;
    margin-bottom: 0;
  }
  
  .message p {
    margin-top: 0;
    margin-bottom: 0 !important;
 }
  
  
.messagesEndRef {
  margin-top: 60px;
}
 
/* Стили для иконки пользователя */
.user_icon {
  width: 40px;
    height: 40px;
    border-radius: 40%;
    object-fit: cover;
    margin-right: 10px;
    
}
  
/* Стиль для каждого чата */
.chat_item {
  display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 2px;
    /* border: 2px solid rgb(143, 139, 139); */
    border-radius: 0px 0 25px 25px;
    background-color: rgb(174 173 173);;
    /* margin: 4px 4px; */
    position: fixed;
    width: 95%;
    /* width: 99vw;
    top: 48px;
    max-width: 450px; */
}
  
/* Стиль для содержимого чата */
/* .chat_content { */
  /* display: flex;
  flex-direction: column;
  flex: 1; Дает контенту максимальное пространство */
/* } */

/* Стиль для верхней части сообщения (имя и сообщение) */
.chat_header {
  display: flex;
  flex-direction: column;
  margin-left: 6px;
}

/* Стиль для имени пользователя */
 .chat_name {
  font-weight: bold;
  font-size: 1rem;
  margin-bottom: 5px;
  color: #da446c;
  
}
   
/* @media screen and (max-width: 380px) {
  .messages {
    margin-top: 70px;
  } */

  /* .chat_item {
    top: 37px;
    width: 95vw;
    left: 2vw;
  } */


/* } */


  